import { Button, Rate, Space, Switch, Table, Tooltip, Typography } from "antd"
import React, { useEffect, useState } from "react"
import moment from "moment"
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
import { defineTableListReviewGoComing } from "./defineTableListReviewGoComing"
import { paginationCustom } from "../../../../components/paginationCustom"
import { getListDataReviewGoComing } from "../../../../services/review"
import HeaderTable from "./HeaderTable"

const { Text } = Typography

const japaneseDateConvert = (string) => {
  const date = moment(string)
  return date.format("YYYY/MM/DD HH:mm")
}

const TableContent = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [searchParams, setSearchParams] = useState({})

  const [listReview, setListReview] = useState(null)
  const [total, setTotal] = useState(0)
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  })

  const onSearchSubmit = (params) => {
    setSearchParams(params)
  }

  const handleGetListReviewGoComing = async () => {
    setIsLoading(true)
    const dataSent = {
      offset:
        (tableParams.pagination.current - 1) * tableParams.pagination?.pageSize,
      limit: tableParams.pagination.pageSize,
      q: searchParams.keyword ? searchParams.keyword : null,
    }
    const { response } = await getListDataReviewGoComing(dataSent)
    if (response.status == 200) {
      setListReview(response.data?.assessList)
      setTotal(response.data?.total)

      setIsLoading(false)
    }
  }
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    })
  }

  useEffect(() => {
    setTableParams({
      pagination: {
        current: 1,
        pageSize: 10,
      },
    })
  }, [searchParams])

  useEffect(() => {
    handleGetListReviewGoComing()
  }, [])

  useEffect(() => {
    if (listReview) {
      handleGetListReviewGoComing()
    }
  }, [tableParams])
  const columns = defineTableListReviewGoComing(handleGetListReviewGoComing)

  return (
    <div>
      <HeaderTable onSubmit={onSearchSubmit} loading={isLoading} />

      <Table
        scroll={{ x: 1500 }}
        columns={columns}
        dataSource={listReview}
        loading={isLoading}
        onChange={handleTableChange}
        pagination={{
          ...tableParams.pagination,
          total: total,
          itemRender: paginationCustom,
          showSizeChanger: false,
          showTotal: (total, range) => (
            <div>
              {range[0]}~{range[1]} 件 表示 &nbsp;&nbsp;&nbsp; (全
              {total}件)
            </div>
          ),
        }}
      />
    </div>
  )
}

export default TableContent
