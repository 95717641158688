import { Rate, Switch, Tooltip } from "antd"
import React from "react"
import { japaneseDateConvert } from "../../../../customUtil/global"
import { changeStatusReview } from "../../../../services/review"
import NoImg from "../../../../images/no_image.jpg"

export const defineTableListReviewGoComing = (handleGetListReviewGoComing) => {
  return [
    {
      title: (
        <div className="text-center flex flex-col   text-xs">
          <span>問い合わせ番号</span>
          <span>注文番号</span>
        </div>
      ),
      width: 130,
      dataIndex: "id",
    },
    {
      title: <div className="text-center   text-xs">画像</div>,

      width: 84,
      render: (_, record) => (
        <img
          style={{ width: "60px", height: "35px", objectFit: "cover" }}
          alt={"thumbnail"}
          src={record.items?.[0]?.thumbnail || NoImg}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null // prevents looping
            currentTarget.src = NoImg
          }}
        />
      ),
    },
    {
      title: <div className="text-center   text-xs">発注日時</div>,
      dataIndex: "created_at",
      key: "created_at",
      width: 70,
      render: (text) => (
        <div style={{ color: "#888888", textAlign: "center" }}>
          {japaneseDateConvert(text)}
        </div>
      ),
    },
    // {
    //   title: <div className="text-center   text-xs">ステータス</div>,
    //   dataIndex: "orderStatus",
    //   key: "orderStatus",
    //   width: 110,
    //   render: (status) => (
    //     <div style={{ ...status?.style, textAlign: "center" }}>{status}</div>
    //   ),
    // },
    // {
    //   title: <div className="text-center   text-xs">対応状況</div>,
    //   dataIndex: "fulfillmentStatus",
    //   key: "fulfillmentStatus",
    //   width: 90,
    //   render: (status) => (
    //     <div style={{ ...status?.style, textAlign: "center" }}>{status}</div>
    //   ),
    // },
    {
      title: <div className="text-center   text-xs">レビュアー</div>,

      width: 140,
      render: (_, record) => (
        <Tooltip title={`${record.user.first_name} ${record.user?.last_name}`}>
          <p className="textCustom !text-black-5">{`${record.user.first_name} ${record.user?.last_name}`}</p>
        </Tooltip>
      ),
    },
    {
      title: <div className="text-center   text-xs">商品名</div>,
      dataIndex: ["product", "title"],
      width: 140,
      render: (text) => (
        <Tooltip title={text}>
          <p className="line-clamp-1 !text-black-5">{text}</p>
        </Tooltip>
      ),
    },
    {
      title: <div className="text-center   text-xs">評価</div>,
      dataIndex: "rating",

      width: 120,
      render: (text) => (
        <div className="text-[#EC5627] flex items-center">
          <Rate
            disabled
            allowHalf
            defaultValue={text}
            style={{ fontSize: 14, color: "#EC5627" }}
          />
        </div>
      ),
    },
    {
      title: <div className="text-center   text-xs">コメント</div>,
      width: 246,
      dataIndex: "comment",

      render: (text) => (
        <Tooltip title={text}>
          <p className="line-clamp-1 !text-black-5">{text}</p>
        </Tooltip>
      ),
    },
    {
      title: <div className="text-center   text-xs">表示</div>,
      width: 90,
      render: (_, record) => (
        <Switch
          checked={record.is_displayed}
          onChange={async (ele) => {
            const { response } = await changeStatusReview(record.id)
            if (response.status == 200) {
              handleGetListReviewGoComing()
            }
          }}
        />
      ),
    },
  ]
}
