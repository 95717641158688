import { Button, Input, Form, Typography, Row, Col } from "antd"
import React from "react"

const { Text } = Typography

const HeaderTable = ({ onSubmit, loading }) => {
  const onFinish = (values) => {
    console.log(values)
    if (onSubmit) {
      onSubmit(values)
    }
  }
  return (
    <div className="bg-[#fafafa] pt-4">
      <Text className="ml-4">絞り込み条件</Text>

      <Form
        colon={false}
        onFinish={onFinish}
        style={{ backgroundColor: "#FAFAFA", padding: "1rem" }}
        labelCol={{ span: 4 }}
        requiredMark={false}
        wrapperCol={{ span: 17 }}
      >
        <Row>
          <Col>
            <Form.Item name="keyword">
              <Input
                placeholder="キーワードで探す"
                style={{ width: "50vw" }}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Button
              htmlType="submit"
              className="w-36 ml-[10px]"
              type="primary"
              loading={loading}
            >
              検索
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default HeaderTable
