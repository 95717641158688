import React from "react"
import TableContent from "./TableContent"

const TableReviewGoComing = () => {
  return (
    <div className="bg-white">
      <TableContent />
    </div>
  )
}

export default TableReviewGoComing
