import request from "../customUtil/request"

export async function getListDataReviewGoComing(param) {
  return request("/admin/orders/assess/list-sell", {
    method: "GET",
    params: param,
  })
}
export async function getListDataReviewGoOut(param) {
  return request("/admin/orders/assess/list-buy", {
    method: "GET",
    params: param,
  })
}

export async function changeStatusReview(id) {
  return request(`/admin/orders/assess/${id}/change-display`, {
    method: "PATCH",
  })
}
